.SelectWrapper {
    transition: all 0.5s ease-in-out;
    display: flex;
    justify-content: baseline;
    align-items: center;
    width: 100%;
    max-width: 400px;
    background-color: var(--field-background);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: 0 0 transparent,inset 0 0 0 1px rgba(255,255,255,0.1);
    border: none;
    border-bottom: 2px solid var(--field-background);
    border-radius: 6px;
}

.SelectWrapper:focus-within {
    transition: all 0.5s ease-in-out;
    border-bottom: 2px solid var(--primary);
}

.Select {
    transition: all 0.5s ease-in-out;
    max-width: 400px;
    width: 100%;
    height: 40px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    border: none;
    border-bottom: 0px solid var(--field-background);
    border-radius: 3px;
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
    font-family: var(--font-family);
    font-weight: 400;
    letter-spacing: normal;
    font-kerning: normal;
    font-size: 14px;
    color: var(--field-color);
    margin-right: 6px;
    padding: 4px;
    line-height: 40px;
    padding-top: 0;
    padding-bottom: 0;
}

.Select > option {
    background: var(--field-background);
}