.FilePickerWrapper {
    transition: all 0.5s ease-in-out;
    display: grid;
    justify-content: baseline;
    align-items: center;
    width: 100%;
    max-width: 400px;
    height: 40px;
    background-color: var(--field-background);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: 0 0 transparent,inset 0 0 0 1px rgba(255,255,255,0.1);
    border: none;
    border-bottom: 2px solid var(--field-background);
    border-radius: 6px;
}

.FilePickerWrapper.E {
    height: 125px;
}

.FilePicker {
    width: 100%;
    height: 100%;
    opacity: 0;
    overflow: hidden;
    max-width: 400px;
}

.FilePickerInfo {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    max-width: 400px;
    overflow: hidden;
}

#FilePickerInfoContentGroup {
    max-width: 100%;
}

#FilePickerInfoContentGroupItem {
    max-width: calc(100% - 80px);
}

#FilePickerClearButton {
    position: relative;
}

#FilePickerExtendedClearButton {
    position: relative;
    max-width: 100px;
    justify-self: center;
    align-self: center;
}

.FilePicker, .FilePickerInfo {
    grid-column: 1;
    grid-row: 1;
}

.FileInfoText, .FileInfoText > * {
    display: block;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis !important;
    white-space: nowrap;
    word-wrap: normal !important;
    line-height: 16px;
    font-family: var(--font-family);
    font-weight: normal;
    color: var(--text);
    word-wrap: break-word;
    word-break: break-word;
    font-weight: 500;
    max-width: 100%;
    padding: 0px 6px 0px 6px;
    box-sizing: border-box;
}