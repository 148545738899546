.Switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }
  
  /* Hide default HTML checkbox */
.Switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.Slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--switch-background);
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.Slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 4px;
  background-color: var(--switch-button);
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

.SwitchInput:checked + .Slider {
  background-color: var(--switch-active-background);
}

.SwitchInput:focus + .slider {
  box-shadow: 0 0 1px var(--switch-active-background);
}

.SwitchInput:checked + .Slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.SwitchLabel {
    font-family: var(--font-family);
    color: var(--text);
    font-size: 14px;
    padding-left: 10px;
    line-height: 20px;
}