.PageSideNavToggle {
    transition: 0.5s ease all;
    padding: 10px 16px 10px 16px;
}

.PageSideNavToggle:hover {
    cursor: pointer;
}

.PageSideNav {
    transition: 0.5s ease all;
    display: none;
    position: relative;
    min-height: 70px;
    flex: 0 1 0%;
    border: 1px solid white;
    box-sizing: border-box;
}

.PageSideNav.E {
    transition: 0.5s ease all;
    display: block;
}

@media screen and (min-width: 768px) {
    .PageSideNav {
        display: block;
        position: relative;
        min-width: 240px;
        flex: 0 1 0%;
        border: 1px solid white;
        box-sizing: border-box;
    }
    .PageSideNavToggle {
        display: none;
    }
}