.FileTop {
    height: 355px;
    max-width: 500px;
    min-width: 300px;
}

.FileImage, .FileVideo {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.DefaultFileTop {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.FileBottom {
    width: 100%;
    max-width: 500px;
}

.ModalTop {
    width: 100%;
    height: 350px;
}

.ModalBottom {
    height: 250px;
    width: 100%;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 5px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #302b2b;
    border-radius: 5px;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(77, 73, 73);
    border-radius: 5px;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

::-webkit-scrollbar-corner {
    display: none;
}

@media only screen and (min-width: 768px) {
    .FileTop {
        min-width: 300px;
    }
}

/*@media only screen and (min-width: 1112px) {
    .FileTop {
        min-width: 350px;
    }
}*/

@media only screen and (min-width: 1205px) {
    .FileTop {
        min-width: 500px;
    }
}