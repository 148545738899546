.Tab {
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: 42px;
    min-width: 100px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: medium;
    color: var(--text);
    border-bottom: 3px solid transparent;
}

.Tab.A {
    transition: all 0.3s ease;
    border-bottom: 3px solid var(--tab-active);
}

.Tab.A > .TabContentWrapper {
    color: var(--tab-active);
}

.TabContentWrapper {
    padding: 0px 3px 0px 3px;
}

.Tab:hover {
    cursor: pointer;
}

.Tab:hover > .TabContentWrapper {
    text-decoration: underline;
}