.Overlay {
    transition: all 1s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    z-index: 6000;
    background: var(--overlay);
}

.Overlay.Closed {
    transition: all 1s ease-in-out;
    display: none;
}