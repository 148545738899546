.PopoutNavWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#PopoutNavPanel {
    box-sizing: border-box;
    max-width: 240px;
    height: 98%;
    margin-left: 5px;
}