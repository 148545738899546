.Button {
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    width: 100%;
    height: 42px;
    min-width: 112px;
    border-radius: 6px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: medium;
}

.ButtonTextWrapper {
    padding: 7px;
}

.Button:hover {
    transition: all 0.3s ease;
    cursor: pointer;
    padding: 0px 1px 0px 1px;
    text-decoration: underline;
}

.Button.E {
    background-color: inherit;
    color: white;
}

.Button.Cprimary {
    background-color: var(--primary-light);
    color: var(--primary);
}

.Button.Cprimary:hover {
    background-color: var(--primary);
    color: black;
}

.Button.Cprimary.F {
    background-color: var(--primary);
    color: var(--button-text);
}

.Button.Cprimary.F:hover {
    color: black;
}

.Button.Csuccess {
    background-color: var(--success-light);
    color: var(--success);
}

.Button.Csuccess.F {
    background-color: var(--success);
    color: black;
}

.Button.Cwarning {
    background-color: var(--warning-light);
    color: var(--warning);
}

.Button.Cwarning.F {
    background-color: var(--warning);
    color: black;
}

.Button.Cdanger {
    background-color: var(--danger-light);
    color: var(--danger);
}

.Button.Cdanger.F {
    background-color: var(--danger);
    color: black;
}

.Button.SC {
    justify-content: space-between;
}

.Button.L {
    background-color: var(--button-loading);
    color: var(--button-loading-text);
}

.Button.L > .ButtonTextWrapper {
    color: var(--button-loading-text);
    padding: 0px 10px 0px 0px;
}

.Button.L:hover {
    background-color: var(--button-loading);
    cursor: not-allowed;
}

