.Text {
    font-family: var(--font-family);
    font-weight: normal;
    color: var(--text);
    word-wrap: break-word !important;
    word-break: break-word;
    font-weight: 500;
}

.Text.Ss {
    font-size: 14px;
    font-size: 1rem;
    line-height: 1.5;
}

.Text.Sm {
    font-size: medium;
}

.Text.Sl {
    font-size: 1.8vh;
}