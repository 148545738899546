.EmptyButton {
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    width: 100%;
    height: 42px;
    border-radius: 8px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: medium;
    background-color: transparent;
}

.EmptyButtonTextWrapper {
    padding: 7px;
}

.EmptyButton:hover {
    transition: all 0.3s ease;
    cursor: pointer;
    text-decoration: underline;
}

.EmptyButton.Cprimary {
    color: var(--primary);
}

.EmptyButton.Csuccess {
    color: var(--success);
}

.EmptyButton.Cwarning {
    color: var(--warning);
}

.EmptyButton.Cdanger {
    color: var(--danger);
}

.EmptyButton.Ctext {
    color: var(--text);
}

.EmptyButton.S {
    height: 35px;
    font-size: small;
}