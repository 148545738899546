.LoginWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.LoginFormWrapper {
    display: flex;
    width: 400px;
    height: 100%;
}