html {
    background-color: var(--filler-primary);
}

.Page {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--filler-primary);
    flex-shrink: 0;
    flex-grow: 1;
}

.Page.H {
    top: 50px;
}

.PageWrapper.SN {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex-grow: 1;
}

@media screen and (min-width: 768px) {
    .PageWrapper.SN {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        flex-shrink: 0;
    }
}