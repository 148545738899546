.CheckboxWrapper {
    position: relative;
}

.Checkbox {
    width: 16px;
    height: 16px;
    top: 3px;
    cursor: pointer;
    position: absolute;
    opacity: 0;
    z-index: 1;
}

.CheckboxSquare {
    padding: 7px;
    border: 1px solid #66676d;
    background: #1D1E24 no-repeat center;
    border-radius: 4px;
    transition: background-color 150ms ease-in,border-color 150ms ease-in;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 3px;
}

.CheckboxWrapper .Checkbox:checked + .CheckboxSquare {
    border-color: var(--primary);
    background-color: var(--primary);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' viewBox='0 0 10 8'%3E%3Cpath d='M.375 2.625L3.375 5.625M3.375 5.625L8.625.375' fill='none' fill-rule='evenodd' stroke='rgb%2829, 30, 36%29' stroke-linecap='round' stroke-width='1.5' transform='translate(.5 1)'/%3E%3C/svg%3E");
}

.Checkbox ~ .CheckboxLabel {
    display: inline-block;
    padding-left: 24px;
    line-height: 24px;
    font-size: 14px;
    position: relative;
    z-index: 2;
    cursor: pointer;
    font-family: var(--font-family);
    color: var(--text);
}