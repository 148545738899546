.Dropdown {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.DropdownContentWrapper {
    display: none;
    position: absolute;
    z-index: 2000;
}

.DropdownContent {
    background-color: var(--filler-secondary);
    border-radius: 6px;
    box-shadow: 0 1px 5px rgba(0,0,0,0.25),0 3.6px 13px rgba(0,0,0,0.175),0 8.4px 23px rgba(0,0,0,0.15),0 23px 35px rgba(0,0,0,0.125);
    padding: 12px 16px;
    z-index: 2000;
}

.Dropdown:hover .DropdownContentWrapper {
    display: block;
}

@media only screen and (min-width : 768px) {
    .DropdownContent {
        width: 100%;
    }
}